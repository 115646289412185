@import url('styles/input.css');

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.map-main {
  height: calc(100VH - 240px);
  width: calc(100% - 48px);
  margin-left: 24px;
  margin-right: 24px;
}

.minutes-dropdown {
  width: 240px;
  display: inline-block;
}

.group-type-dropdown {
  width: 130px;
  display: inline-block;
  margin-left: 12px;
}

.export-type-dropdown {
  width: 90px;
  display: inline-block;
  margin-left: 0px;
}

.export-button {
  width: 120px;
  display: inline-block;
  margin-left: 0px;
}

.main-input {
  vertical-align: top;
  width: 240px;
  display: inline-block;
  margin-left: 12px;
  margin-right: 24px;
  margin-bottom: 18px;
}

.map-header {
  text-align: left;
  display: block;
  margin: 0px 24px 0px 24px;
  width: calc(100% - 96px);
  background-color: whitesmoke;
  padding: 18px 24px 18px 24px;
}

.map-header2 {
  text-align: left;
  display: block;
  margin: 0px 24px 0px 24px;
  margin-top: -22px;
  padding: 0px 24px 0px 24px;
  width: calc(100% - 96px);
  background-color: whitesmoke;
  text-align: center;
}

.map-loading img {
  max-height: 36px;
}
.map-loading {
  display: inline-block;
  vertical-align: top;
  line-height: 34px;
}

.clusters-box {
  border-style: solid;
  border-width: 2px;
  border-color: lightgray;
  padding: 6px 18px 6px;
  background-color: white;
  width: 142px;
  margin-top: 8px;
  margin-left: -8px;
}
.clusters-box-row {
  display: block;
  white-space: nowrap;
  margin-top: 2px;
  margin-bottom: 2px;
}
.cluster-box-state {
  width: 25px;
  display: inline-block;
  margin-left: 12px;
}
.cluster-box-len {
  width: 28px;
  display: inline-block;
  margin-left: 38px;
  font-weight: bold;
}
.cluster-box-blue {
  font-weight: bold;
  color: #0053ed;
}
.cluster-box-header {
  font-size: 13.5px;
}
.cluster-box-title {
  font-weight: bold;
  color: #0053ed;
  margin-bottom: -4px;
  display: block;
  font-size: 18px;
}

.displayed-days-title {
  text-align: right;
  width: 74px;
  display: inline-block;
  margin-right: 18px;
}

.displayed-days-total {
  font-weight: bold;
  color: #930202;
}

.displayed-days-top {
  font-weight: bold;
  color: #930202;
  margin-bottom: -4px;
  display: block;
  font-size: 18px;
}
